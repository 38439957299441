.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:regular,bold,italic&subset=latin,latin-ext');

div {
  font-family: 'Source Sans Pro';
}

p {
  font-family: 'Source Sans Pro';
}

.navbar-dark .navbar-toggler-icon {
  background-image: url('../src/sources/images/menu.png')!important;
  width: 80px;
  height: 52px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-layout {
  /* margin-right: 20%;
  margin-left: 20%; */
}

.logo {
  width: 250px;
  height: 80px;
  margin-left: 50px;
}

.mobile-show {
  display: none;
}

.custom-navbar {
  background-color: #000;
}

.ebBtnContainer {
  padding-left: 16px;
}

.ebBtn {
  background-color: #fed440;
  color: #000;
  border: 1px solid;
  height: 48px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 18px;
  font-family: 'Source Code Pro';
}

.ebBtn2 {
  background-color: #000;
  color: #fff;
  min-height: 68px;
  padding-top: 21px;
  padding-bottom: 21px;
  padding-right: 42px;
  padding-left: 42px;
  text-decoration-line: underline;
  font-size: 18px;
  font-family: 'Source Code Pro';
}

.ebBtn:hover {
  color: #000;
}

.ebBtn2:hover {
  color: #fed440;
  cursor: pointer;
}

.ebLinkContainer {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
  padding-left: 16px;
}

.ebLink {
  color: #fff;
  font-size: 18px;
  font-family: 'Source Code Pro';
}

.ebLink:hover {
  color: #fed440;
  cursor: pointer;
  text-decoration-line: underline;
}

.ebLinkBackBtn {
  color: #fff;
  font-size: 18px;
  border-bottom: 1px solid white;
  vertical-align: sub;
  cursor: pointer;
}

.subNav {
  padding: 5px;
  background-color: #fed440;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.subNav.solutions.arrow-top:after {
  content: " ";
  position: absolute;
  right: 560px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #fed440;
}

.subNav.resources.arrow-top:after {
  content: " ";
  position: absolute;
  left: 290px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #303030;
}

.alignRow {
  text-align: center;
}

.subNavItemContainer {
  margin: 10px;
}

.sub-nav-item {
  color: #000;
  font-size: 18px;
  padding: 21px;
  font-family: 'Source Code Pro';
}

.sub-nav-item:hover {
  color: #000;
  font-size: 18px;
  text-decoration-line: underline;
}

.chart-img {
  width: 110px;
}

.chart-img-left {
  padding: 10px 0;
  text-align: left;
}

.chart-img-right {
  padding: 10px 0;
  text-align: right;
}

.verticalAlignItem {
  display: inline-grid;
  height: 100%;
  align-items: center;
  /* vertical-align: middle; */
  /* margin-top: 20px; */
}

.verticalAlignItem2 {
  display: inline-grid;
  height: 50%;
  align-items: center;
}

.horizontalAlignItem {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
}

.twocolordiv  {
  position: relative;
  z-index: 9;
  background: white;
  padding: 75px;
}

.twocolordiv:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 50%;
  left: 0;
  background: #ebebeb;
}

.two-color-card-container {
  padding-left: 5%;
  padding-right: 5%;
}

.two-color-card-text-container {
  padding-bottom: 35px;
}

.two-color-card-text {
  font-size: 3.4375em;
}

.two-color-card-subtitle {
  text-align: center;
  font-size: 3.4375em;
}

.two-color-card-subtext {
  text-align: center;
  font-size: 1.625em;
  padding-right: 50px;
  padding-left: 50px;
}

.two-color-card-sub-container {
  padding: 75px;
}

.table-container {
  padding: 75px;
}

.table-header-container {
  padding-left: 5%;
  padding-right: 5%;
}

.table-title {
  font-size: 3.4375em;
}

.table-text {
  color: #000;
}

.table-text-two {
  color: #FFF;
}

.table-text:hover {
  color: #0000FF;
}

.table-text-two:hover {
  color: #0000FF;
}

.table-sub-container {
  padding-left: 75px;
  padding-right: 75px;
}

.table-subtext {
  font-size: 1.625em;
  padding-right: 50px;
  padding-left: 50px;
}

.table-content {
  display: flex;
  padding: 184px;
  padding-bottom: 10px;
  padding-top: 30px;
}

.table-content-container {
  padding-top: 1px;
  padding-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
}

.table-column {
  padding-left: 25px;
}

.table-column-container {
  display: flex;
}

.table-content-subtext {
  padding-bottom: 20px;
  font-size: 1.3125em;
}

.paragraph-container {
  text-align: center;
  padding: 75px;
}

.paragraph-header-container {
  padding-left: 5%;
  padding-right: 5%;
}

.basic-page-container {
  padding-left: 396px;
  padding-right: 396px;
  padding-bottom: 110px;
}

.basic-page-title-main {
  font-size: 3.4375em;
}

.basic-page-title-container {
  padding-top: 110px;
  padding-bottom: 55px;
}

.basic-page-subtitle-container {
  padding-bottom: 26px;
  font-size: 1.3125em;
}

.basic-page-content-container {
  font-size: 1.3125em;
}

.basic-page-content-block {
  padding-bottom: 20px;
}

.basic-page-content-block-title {
  font-size: 1.625em;
}

.basic-page-content-block-subtitle {
  padding-bottom: 5px;
  font-size: 1.3125em;
}

.basic-page-content-block-text {
  font-size: 21px;
}

.paragraph-title-main {
  font-size: 3.4375em;
}

.paragraph-title {
  font-size: 3.4375em;
}

.paragraph-sub-container {
  padding-right: 75px;
  padding-left: 75px;
}

.paragraph-subtitle {
  font-size: 1.625em;
}

.paragraph-subtext {
  font-size: 1.625em;
  padding-right: 50px;
  padding-left: 50px;
}

.modal-text {
  font-size: 1.3125em;
}

.horizontal-diff-card-container {
  text-align: center;
  padding: 75px;
  padding-top: 25px;
}

.horizontal-diff-container {
  display: flex;
  text-align: center;
}

.horizontal-diff-sub-container {
  width: 50%;
}

.horizontal-diff-img {
  width: 150px;
  height: 150px;
}

.footer-text {
  color: #FFF;
  text-decoration-line: underline;
}

.footer-text:hover {
  color: #fed440;
}

.form-container {
  width: 625px;
}

.form-input {
  transform: scale(1.5);
}

.field-container {
  width: 100%;
  display: inline-flex;
}

.field-box-container {
  width: 50%;
}

.field-label {
  font-size: 1.3125em;
}

.homeimg {
  width: 100%;
  height: 900px;
  object-fit: cover;
}

.headerimg {
  width: 100%;
  height: 500px;
  object-fit: none;
}

.home-img-text {
  font-size: 54px;
  line-height: normal;
  padding-bottom: 25px;
}

.home-img-text-sub {
  font-size: 34px;
  line-height: normal;
}

.home-img-text-container {
  padding: 42px 10px;
}

.text-block {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: #fff;
  color: #000;
  padding-left: 20px;
  padding-right: 20px;
  width: 50%;
  height: 355px;
}

.about-us-title-container {
  padding: 75px;
  text-align: center;
}

.about-us-table {
  padding: 75px;
}

.about-us-table-container {
  padding-left: 396px;
  padding-right: 396px;
}

.diffcard-element {
  padding-right: 40px;
  padding-left: 40px;
}

.diffcard-text-content-title {
  font-size: 2.125em;
  padding-bottom: 10px;
}

.diffcard-title {
  font-size: 3.4375em;
}

.diffcard-subtitle {
  font-size: 1.625em;
}

.diffcard-container {
  width: 100%;
  display: flex;
  padding: 75px;
  padding-left: 10%;
  padding-right: 10%;
}

.diffcard-img {
  width: 50%;
}

.diffcard-text {
  width: 50%;
}

.diffcard-text-content {
  padding: 35px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 100%;
}

.diffcard-text-content-subtext {
  font-size: 1.3125em;
}

.testimonial-container {
  padding: 50px;
  padding-right: 10%;
  padding-left: 10%;
}

.testimonial-title {
  font-size: 1.625em;
  padding-bottom: 20px;
  color: #fed440;
  font-family: 'Source Code Pro';
}

.testimonial-text {
  font-size: 3.4375em;
  color: #fff;
}

.testimonial-subtext {
  font-size: 1.625em;
  padding-top: 20px;
  color: #fff;
}

.testimonial-main {
  background-color: #000;
  align-items: center;
  padding: 35px;
}

.custom-divider {
  padding: 50px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left: 8%;
  margin-right: 8%;
}

.custom-divider2 {
  padding-top: 1px;
  padding-bottom: 10px;
  margin-right: 25px;
}

.request-demo-title {
  font-size: 3.4375em;
}

.request-demo-subtitle {
  font-size: 1.3125em;
}

.request-demo-title-container {
  padding: 75px;
}

.contact-us-container {
  padding: 75px;
  padding-top: 10px
}

.contact-us-title-container {
  text-align: center;
  /* padding: 75px; */
}

.contact-us-title {
  font-size: 3.4375em;
}

.contact-us-card-container {
  display: inline-flex;
  width: 100%;
}

.contact-us-card-right {
  width: 50%;
  padding-left: 5%;
}

.contact-us-card-left {
  width: 50%;
  padding-right: 5%;
}

.contact-us-card-content-container {
  background-color: #ebebeb;
  height: 311px;
}

.contact-us-card-content {
  padding: 48px;
}

.contact-us-card-content-title {
  font-size: 2.125em;
  margin-bottom: 10px;
}

.inquiry-container {
  background-color: #fed440;
  padding: 50px;
}

.modal-container {
  padding: 42px;
}

.footer {
  display: flex;
  justify-content: space-evenly;
  padding: 35px
}

.footer-logo {
  padding: 50px;
  padding-left: 10.5%;
  padding-bottom: 35px;
}

@media only screen and (max-width: 1570px) {
  .home-img-text {
    font-size: 52px;
  }
}

@media only screen and (max-width: 1570px) {
  .home-img-text {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1570px) {
  .home-img-text {
    font-size: 45px;
  }
}

@media only screen and (max-width: 1380px) {
  .diffcard-text-content-title {
    font-size: 1.625em;
  }

  .diffcard-text-content-subtext {
    font-size: 1.3125em;
  }

  .diffcard-element {
    padding-right: unset;
    padding-left: unset;
  }
}

@media only screen and (max-width: 1350px) {
  .home-img-text-sub {
    font-size: 26px;
  }

  .diffcard-text-content-subtext {
    font-size: 1em;
  }

  .diffcard-element {
    padding-right: unset;
    padding-left: unset;
  }
}

@media only screen and (max-width: 1125px) {
  .logo {
    width: 200px;
    height: 80px;
    margin-left: 1px;
  }

  .homeimg {
    width: 100%;
    height: 414px;
    object-fit: cover;
  }

  .headerimg {
    width: 100%;
    height: 414px;
    object-fit: cover;
  }

  .text-block {
    text-align: center;
    position: unset;
    background-color: #fff;
    color: #000;
    width: 100%;
    height: unset;
  }

  .two-color-card-subtitle {
    text-align: center;
    font-size: 2.125em;
  }

  .two-color-card-subtext {
    font-size: 1.3125em;
  }

  .diffcard-text-content {
    padding: 25px;
    height: 100%;
  }

  .diffcard-text-content-subtext {
    font-size: 1.3125em;
  }

  .diffcard-container {
    width: 100%;
    display: inline-grid;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .diffcard-img {
    width: 100%;
  }
  
  .diffcard-text {
    width: 100%;
    order: 2;
  }

  .testimonial-text {
    font-size: 2.125em;
    color: #fff;
  }

  .about-us-title-container {
    padding: 26px;
    padding-top: 42px;
  }

  .about-us-table-container {
    padding-left: 1px;
    padding-right: 1px;
  }

  .two-color-card-sub-container {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }

  .table-title {
    font-size: 2.125em;
  }

  .table-content-container {
    padding-bottom: 1px;
  }

  .table-content {
    padding: 50px;
  }

  .table-subtext {
    font-size: 1.3125em;
  }

  .verticalAlignItem {
    margin-top: 10px;
  }

  .verticalAlignItem2 {
    margin-top: 10px;
  }

  .contact-us-card-content {
    padding: 16px;
  }

  .home-img-text {
    font-size: 55px;
  }
  
  .home-img-text-sub {
    font-size: 26px;
    line-height: normal;
  }
  
  .basic-page-container {
    padding-left: 75px;
    padding-right: 75px;
  }

  .paragraph-title {
    font-size: 2.125em;
  }

  .paragraph-subtext {
    font-size: 1.3125em;
  }

  .request-demo-title {
    font-size: 2.125em;
  }

  .request-demo-subtitle {
    font-size: 1.3125em;
  }

  .ebLink {
    cursor: pointer;
  }

  .ebBtnContainer {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 960px) {
  .logo {
    width: 155px;
    height: 34px;
  }

  .custom-navbar {
    background-color: #000;
    padding-right: 0%;
    padding-left: 0%;
  }

  .field-box-container {
    width: unset;
  }

  .ebBtn:hover {
    color: #fed440;
  }

  .sub-nav-item {
    color: #FFF;
    padding: 10px;
    text-decoration-line: underline;
  }

  .sub-nav-item:hover {
    color: #fed440;
  }

  .navContainer {
    padding-top: 15px;
  }

  .homeimg {
    width: 100%;
    height: 414px;
    object-fit: cover;
  }

  .headerimg {
    width: 100%;
    height: unset;
    object-fit: cover;
  }

  .ebLinkContainer {
    padding-left: inherit;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ebBtnContainer {
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ebBtn {
    background-color: #000;
    color: #fff;
    border: 0px;
    height: inherit;
    padding: inherit;
    text-decoration-line: underline;
  }

  .ebLink {
    padding: inherit;
    padding-top: 5px;
    padding-bottom: 5px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .text-block {
    position: unset;
    background-color: #fff;
    color: #000;
    width: 100%;
    height: unset;
  }

  .about-us-title-container {
    padding: 26px;
    padding-top: 42px;
  }

  .about-us-table {
    padding: 26px;
  }

  .verticalAlignItem {
    display: inline-block;
    margin-top: 0px;
  }

  .verticalAlignItem2 {
    display: inline-block;
    margin-top: 0px;
  }

  .home-img-text {
    font-size: 2.125em;
  }
  
  .home-img-text-sub {
    font-size: 1.3125em;
  }

  .home-img-text-container {
    padding: 16px;
    padding-top: 42px;
    padding-bottom: 42px;
  }

  .diffcard-text-content-title {
    font-size: 1.625em;
  }

  .diffcard-title {
    font-size: 2.125em;
    line-height: normal;
    padding-bottom: 20px;
  }
  
  .diffcard-subtitle {
    font-size: 1em;
    line-height: normal;
  }

  .diffcard-container {
    width: 100%;
    display: inline-grid;
    padding: 0px;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
  }

  .diffcard-img {
    width: 100%;
  }
  
  .diffcard-text {
    width: 100%;
    order: 2;
  }

  .diffcard-text-content {
    padding: 25px;
    height: unset;
  }

  .testimonial-container {
    padding: 50px;
    padding-right: 2%;
    padding-left: 2%;
  }

  .testimonial-title {
    font-size: 1em;
    padding-bottom: 20px;
    color: #fed440;
    font-family: 'Source Code Pro';
  }

  .testimonial-text {
    font-size: 1.625em;
    color: #fff;
  }

  .testimonial-subtext {
    font-size: 1em;
    padding-top: 20px;
    color: #fff;
  }

  .testimonial-main {
    background-color: #000;
    align-items: center;
    padding: 35px;
    padding-bottom: 5px;
  }

  .custom-divider {
    padding: 50px;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-left: 0%;
    margin-right:0%;
  }

  .custom-divider2 {
    padding-top: 1px;
    padding-bottom: 10px;
    margin-right: 1px;
  }

  .mobile-hidden {
    display: none;
  }

  .mobile-show {
    display: block;
  }

  .footer {
    display: inline-block;
    padding-left: 15%;
    padding-top: 20px;
  }

  .footer-logo {
    padding: 50px;
    padding-left: 12.5%;
    padding-bottom: 35px;
  }

  .modal-text {
    font-size: 18px;
  }

  .twocolordiv  {
    background: white;
    padding: 0px;
  }

  .twocolordiv:before {
    background: #fff;
  }

  .two-color-card-container {
    padding-left: 0%;
    padding-right: 0%;
  }

  .two-color-card-text {
    font-size: 2.125em;
  }

  .two-color-card-text-container {
    padding: 16px;
    padding-top: 42px;
  }

  .two-color-card-sub-container {
    padding: 35px;
  }

  .two-color-card-subtitle {
    font-size: 1.625em;
  }
  
  .two-color-card-subtext {
    font-size: 1.3125em;
    padding: 1px;
    padding-top: 10px;
  }

  .table-container {
    padding: 16px;
    padding-top: 42px;
  }

  .table-title {
    font-size: 1.625em;
  }

  .table-sub-container {
    padding: 1px;
  }
  
  .table-subtext {
    font-size: 1.3125em;
    padding: 1px;
  }

  .table-content {
    padding: 1px;
  }

  .table-content-container {
    padding-bottom: 1px;
  }

  .table-column {
    padding: 25px;
    padding-bottom: 1px;
  }

  .table-column-container {
    display: inline-block;
  }

  .paragraph-container {
    padding: 16px;
    padding-top: 42px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .paragraph-header-container {
    padding-left: 26px;
    padding-right: 26px;
  }

  .paragraph-title-main {
    font-size: 2.125em;
  }

  .paragraph-title {
    font-size: 1.625em;
  }

  .paragraph-sub-container {
    padding: 1px;
  }

  .paragraph-subtext {
    font-size: 1.3125em;
    padding: 1px;
  }

  .horizontal-diff-card-container {
    padding: 16px;
  }

  .horizontal-diff-container {
    display: inline-block;
  }

  .horizontal-diff-sub-container {
    width: 100%;
    padding-top: 20px;
  }

  .horizontal-diff-img {
    width: 150px;
    height: 150px;
  }

  .request-demo-title {
    font-size: 1.625em;
  }

  .request-demo-subtitle {
    font-size: 1em;
  }

  .request-demo-title-container {
    padding: 16px;
  }

  .contact-us-container {
    padding: 26px;
  }

  .contact-us-title {
    font-size: 2.125em;
  }

  .contact-us-title-container {
    text-align: center;
    padding-top: 42px;
  }

  .contact-us-card-container {
    display: inline-block;
  }

  .contact-us-card-right {
    width: 100%;
    padding-left: 0%;
    padding-bottom: 42px;
  }

  .contact-us-card-left {
    width: 100%;
    padding-right: 0%;
    padding-bottom: 42px;
  }

  .contact-us-card-content-container {
    background-color: #ebebeb;
    height: unset;
  }

  .contact-us-card-content {
    padding: 16px;
  }

  .contact-us-card-content-title {
    font-size: 1.625em;
  }

  .inquiry-container {
    background-color: #fed440;
    padding: 26px;
  }

  .form-container {
    width: 100%;
  }

  .field-container {
    display: inline-block;
    width: 100%;
  }

  .field-label {
    font-size: 1em;
  }

  .basic-page-container {
    padding-left: 26px;
    padding-right: 26px;
    padding-bottom: 42px;
  }

  .basic-page-title-main {
    font-size: 2.125em;
  }

  .basic-page-title-container {
    padding-top: 42px;
    padding-bottom: 10px;
  }

  .basic-page-content-block-title {
    font-size: 1.3125em;
  }

  .modal-container {
    padding: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .chart-img-right {
    padding: 10px 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .chart-img-left {
    padding: 10px 0;
    text-align: center;
    margin-bottom: 20px;
  }
}